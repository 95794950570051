.map {
  display: none;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    cursor: pointer;
    border: solid 1px #EAECF0;
    border-radius: 8px;
  }
}

.map .hovering {
  fill: #1D385E !important;
}

.map .selected {
  fill: #1D385E !important;
}

.map .selected-hovering {
  fill: #1D385E !important;
}

.tooltip-anchor {
  position: fixed;
  top: 0px;
  left: 0px;
}

.tooltip-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

.tooltip-member-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.tooltip-content {
  margin-left: 16px;
}

.tooltip-title {
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  color: #1D385E;
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.tooltip-name {
  font-family: Inter;
  font-size: 20px;
  color: #1D385E;
  font-weight: 700;
  margin-bottom: 4px;
}

.tooltip-party {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #1D385E;
  margin-left: 8px;
}

.tooltip-state {
  font-family: Inter;
  font-size: 14px;
  color: #1D385E;
  font-weight: 300;
}

.tooltip-score-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tooltip-score {
  font-family: Inter;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 700;
  color: #1D385E;
}

.tooltip-divider {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  border-bottom: solid 1px #EAECF0;
}

